
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        


















































































































































































































































































































































.svg-cont {
  display: inline-block;
  position: relative;
  width: 100%;

  .line-chart {
    outline: transparent;
    user-select: none;

    .axis {
      text {
        fill: $--clb-body-font;
        font-size: 11px;
        -webkit-font-smoothing: antialiased;
      }

      .domain {
        stroke: transparent;
      }

      .tick {
        line {
          stroke: $--clb-disabled-color;
        }
      }

      .axis-x {
        text {
          font-size: 11px;
          text-anchor: middle;
          transform: translate(-5px, 6px) rotate(0deg);
          -webkit-font-smoothing: antialiased;
        }
      }
    }
  }
}

@include xs-only {
  .svg-cont {
    .line-chart {
      .axis {
        text,
        .axis-x text {
          font-size: 16px;
        }
      }
    }
  }
}
